/* Basic editor styles */
.ProseMirror > * {
  padding: 0.25rem;
  margin: 0;
  cursor: text;
}

.ProseMirror a {
  cursor: pointer;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.wiki-block {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  padding: 0.05rem;
  margin: 0.05rem 0;
}

.wiki-block.block-editable {
  grid-template-columns: min-content min-content auto min-content;
}

.wiki-block .material-icons {
  /* margin: 0.1rem; */
  color: grey;
}

.wiki-block {
  border: 1px solid transparent;
  border-radius: 4px;
}

.ProseMirror-focused:focus-visible {
  border-radius: 4px;
  outline: 1px solid rgba(0, 0, 0, 0.1);
}

/* .wiki-block:hover { */
/* border: 1px solid #ddd5; */
/* background-color: rgba(100,100,100,0.05); */
/* border-radius: 4px; */
/* } */

.wiki-block svg {
  border-radius: 1px;
  background-color: transparent;
  line-height: default;
}

.wiki-block svg:hover {
  background-color: #ddd;
}

.wiki-block .material-icons,
.delete-block {
  opacity: 0;
  line-height: default;
  padding: 0;
  margin: 0;
}

.wiki-block:hover .material-icons,
.wiki-block:hover .delete-block,
.delete-block:focus {
  opacity: 1;
}

pre {
  background: #0d0d0d;
  border-radius: 0.5rem;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
}

pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.wiki-block-wrapper {
  position: relative;
  max-width: 85ch;
}

.wiki-toolbar {
  /* position: absolute; */
  min-width: max-content;
  /* top: -2.5em; */
  /* right: 0; */
  z-index: 100;
  /* background-color: rgb(255,255,255); */
  background-color: white;
  /* add a drop shadow */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
  /* opacity: 0.9; */
  /* max-width: 80%; */
  mix-blend-mode: normal;
}

@media only screen and (max-width: 650px) {
  .wiki-block-wrapper {
    position: inherit;
  }
}

.wiki-toolbar .MuiButtonGroup-root {
  display: flex !important;
  flex-wrap: wrap !important;
}

.wiki-toolbar .MuiButtonGroup-grouped {
  border-right-color: unset !important;
}

.wiki-block a:not(.existing-page-link) {
  text-decoration: underline currentcolor dotted;
  color: #1976d2;
}

.wiki-block a {
  color: #1976d2;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.2.0/styles/github-dark.min.css');
